body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tile {
  width: 48px;
  height: 48px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  color: white;
  text-transform: uppercase;
  user-select: none;
  border: solid 1px #000000;
  margin-bottom: 1px;
}

.wordle-click-letter {
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
  max-width: 100px;
  white-space: normal;
}

.tile-wrapper {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
}

.tile-blank {
  background-color: white;
  color: darkslategray;
}

.tile-in-word {
  background-color: #c9b458;
}

.tile-correct-place {
  background-color: #6aaa64;
}

.tile-not-in-word {
  background-color: #787c7e;
}

.wordle-row {
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 8px;
}

.ds-c-choice + label {
  font-size: x-small;
}

.valid-word-list {
  max-width: 500px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid black;
}

.wordle-assistant-max-width {
  max-width: 500px;
}

.keyboard {
  margin-top: 16px;
  max-width: 500px;
  margin-left: 4px;
}

.keyboard-row {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.keyboard-row > button {
  /* margin-right: 0px; */
  font-size: 15px;
}
/*
.keyboard-button {
  background-color: lightgrey;
  color: black;
  font-weight: 700;
  font-size: 15px;
  border: black solid 1px;
  min-width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
*/
.keyboard-button {
  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: #d3d6da;
  color: black;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

.button-palette {
  width: 16px;
  height: 16px;
  border: 1px solid black;
}

.button-not-in-word {
  margin-top: -5px;
  margin-left: 8px;
}

.linked-in {
  color: #0a66c2;
  cursor: pointer;
}

.ds-base {
  display: flex;
  min-height: 95vh;
  flex-direction: column;
}

.wordle-header-main {
  flex: 1 0 auto;
}

.ds-u-margin-right--2px {
  margin-right: 2px;
}

.ds-u-margin-top--2px {
  margin-top: 2px;
}

.ds-u-margin-left--4px {
  margin-left: 4px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 4px;
}

.no-padding-no-margin {
  margin: 0px !important;
  padding: 0px !important;
}
.instructions {
  margin-top: 4px;
  margin-bottom: 4px;
}
